<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-row>
                <v-col cols="6">
                    <v-text-field v-model="barcode" placeholder="Barcode or Serial #" flat hide-details solo-inverted 
                        v-mask="'############'" @keydown.enter="loadItem" ref="barcode"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="cleaningBin" placeholder="Cleaning bin" flat hide-details solo-inverted 
                        ref="bin"></v-text-field>
                </v-col>
            </v-row>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="item != null && item.alert != null && item.alert != false">
                        <v-alert :color="item.alert.color" :icon="alertIcon" v-html="item.alert.message" dark></v-alert>
                    </v-col>
                    <v-col cols="6">
                        <div class="subtitle-1 mb-3">Last scanned item</div>

                        <v-alert dense text border="left" v-if="item != null && item.message" type="warning">{{ item.message }}</v-alert>

                        <div class="headline grey--text mt-5" v-show="item == null">Scan a barcode to begin</div>

                        <v-simple-table v-show="item != null">
                            <tbody>
                                <tr>
                                    <th>barcode</th>
                                    <td>{{ item == null ? '' : item.barcode }}</td>
                                </tr>
                                <tr>
                                    <th>Serial</th>
                                    <td>{{ item == null ? '' : item.id }}</td>
                                </tr>
                                <tr>
                                    <th>Current status</th>
                                    <td>{{ item == null ? '' : item.status }}</td>
                                </tr>
                                <tr>
                                    <th>Product</th>
                                    <td>{{ item == null ? '' : `[${item.product.style}] ${item.product.description}` }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <v-row class="mt-5 mb-1" v-if="item != null">
                            <v-col v-show="item.message && item.statusId != 8">
                                <v-btn depressed block color="warning" @click="forceDialog = true">Force check-in</v-btn>
                            </v-col>
                            <v-col v-show="item.statusId != 6">
                                <v-btn depressed block color="error" @click="damageDialog = true">Mark as damaged</v-btn>
                            </v-col>
                        </v-row>

                        
                    </v-col>

                    <v-col cols="6">
                        <div class="subtitle-1 mb-3">Items not returned yet</div>

                        <v-data-table :items="missingItems" :headers="headers" :footer-props="paginationOptions" item-key="id" 
                            :options="pagination" :loading="loadingMissingItems">
                            <template v-slot:item.actions="{ item }">
                                <v-menu transition="slide-y-transition" bottom left min-width="150">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="lost(item.barcode)">
                                            <v-list-item-content>
                                                <v-list-item-title>Mark as lost</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="forceDialog" width="400">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to change the status of this item to <span class="newStatus">In Laundry</span>?

                    <v-checkbox v-model="addTurns" label="Increase item turns" hide-details></v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="forceDialog = false">No</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" :loading="forcing" @click="force">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="damageDialog" width="400">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to change the status of this item to <span class="newStatus">Damaged</span>?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="damageDialog = false">No</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="damaged">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';

export default {
    data: () => ({
        loadingView         : false,
        loadingMissingItems : false,
        barcode             : '',
        cleaningBin         : '',
        headers             : [
            { text: 'Barcode', value: 'barcode', sortable: true },
            { text: 'Type', value: 'product.category', sortable: true },
            { text: 'Style', value: 'product.style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Back date', value: 'dateDueBack', sortable: true },
            { text: 'Last Order', value: 'orderNum', sortable: true },
            { text: '', value: 'actions', sortable: false }
        ],
        pagination          : {
            sortBy: ['dateDueBack']
        },
        paginationOptions   : { itemsPerPageOptions: [ 50, 75, 100, -1 ] },

        forceDialog         : false,
        damageDialog        : false,

        forcing             : false,
        damaging            : false,

        addTurns            : false,
    }),
    computed: {
        ...mapGetters({
            item            : 'stations/checkIn/item',
            missingItems    : 'stations/checkIn/missingItems',
        }),
        alertIcon() {
            if( _st.isNUE( this.item.alert ) )
                return '';

            switch( this.item.alert.priority ) {
                case '0':   return 'mdi-alert-circle-outline';
                case '1':   return 'mdi-exclamation-thick';
            }

            return '';
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadMissingItems    : 'stations/checkIn/loadMissingItems',
            scanItem            : 'stations/checkIn/scanItem',
            setLostItem         : 'stations/checkIn/registerLostItem',
            setDamageItem       : 'stations/checkIn/registerDamageItem',
            forceItem           : 'stations/checkIn/force'
        }),
        async init() {
            try {
                this.loadingMissingItems = true;
                await this.loadMissingItems();
                this.loadingMissingItems = false;
            }
            catch(error) {
                this.loadingMissingItems = false;
            }
        },
        async loadItem() {
            try {
                this.playBeep();
                
                this.loadingView = true;
                await this.scanItem( this.barcode, this.cleaningBin );
                this.loadingView = false;

            }
            catch(error) {
                this.loadingView = false;
            }
            finally {
                this.barcode = '';
            }
        },
        async force() {
            try {
                this.forcing = true;
                await this.forceItem({ addTurn: this.addTurns, bin: this.cleaningBin });
                this.forcing = false;
                this.addTurns = false;
                this.forceDialog = false;
            }
            catch(error) {
                this.forcing = false;
            }
        },
        async damaged() {
            try {
                this.damaging = true;
                await this.setDamageItem();
                this.damaging = false;
                this.damageDialog = false;
            }
            catch(error) {
                this.damaging = false;
            }
        },
        async lost( barcode ) {
            await this.setLostItem( barcode );
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>
.newStatus {
    font-weight: bold;
    font-style: italic;
}
</style>